import {db} from '../Auth/firebaseApp/firebaseApp';
import { getFirestore, collection, collectionGroup, setDoc, addDoc, getDoc, doc, query, getDocs, where, get } from "firebase/firestore";

async function createSubFolder(pathToDoc, data) {
  const docRef = doc(db, pathToDoc);
  const subcollectionRef = collection(docRef, "subfolder");
  const newDocRef = await addDoc(subcollectionRef, data);

  const idAdded = await setDoc(newDocRef, {
    folder_id: newDocRef.id,
    path: newDocRef.path,
  }, { merge: true });
  return subcollectionRef.path;
}

async function getDocumentNamesFromPath(path) {
  const pathSegments = path.split('/');
  const documentRefs = [];

  // Iterate over each segment of the path and construct a document reference
  let collectionRef = collection(db, 'folders');
  for (let i = 1; i < pathSegments.length; i += 2) {
    const currentDocumentId = pathSegments[i];
    documentRefs.push(doc(collectionRef, currentDocumentId));

    // Update the collection reference for the next iteration
    collectionRef = collection(doc(collectionRef, currentDocumentId), 'subfolder');
  }

  const documentNames = [];

  // Iterate over each document reference and get the document name
  for (const documentRef of documentRefs) {
    const documentSnapshot = await getDoc(documentRef);
    const documentData = documentSnapshot.data();

    if (!documentData) {
      // Document not found, return empty array
      return [];
    }

    documentNames.push({name:documentData.name, path: documentData.path, folder_id: documentData.folder_id});
  }

  return documentNames;
}



async function getPath(documentId) {
  if (!documentId) {
    throw new Error('Missing document ID');
  }

  const querySnapshot = await getDocs(collectionGroup(db, "folders"), where("folder_id", "==", documentId));

  if (querySnapshot.empty) {
    throw new Error('Document not found');
  }

  const docSnapshot = querySnapshot.docs[0];
  console.log(docSnapshot)
  const parentCollectionId = docSnapshot.ref.parent.parent.id;
  const fullPath = `${parentCollectionId}/${docSnapshot.id}`;
  console.log(fullPath)
  return fullPath;
}

async function getDocumentsInCollection(path) {
  const snapshot = await getDocs(collection(getFirestore(), path));
  const documents = [];

  snapshot.forEach(doc => {
    documents.push(
      doc.data()
    );
  });

  return documents;
}



// Function to add a document to a folder based on the path returned from the other function
function addDocToFolder(path, doc) {
    db.doc(path).set(doc).then(() => {
        // Document added to collection successfully
    });
  }

  async function getCurFolder(pathToDoc) {
    const docRef = doc(db, pathToDoc);
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();
    return docData;
  }

  const getUserData = async (userId) => {
    const userDocRef = doc(collection(db, 'users'), userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      throw new Error('User document not found!');
    }
  };

  async function getUserById(userId) {
    const userDocRef = doc(collection(db, 'users'), userId);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      throw new Error('User document not found!');
    }
  }
  

export {createSubFolder, addDocToFolder, getUserById, getCurFolder, getUserData, getPath, getDocumentsInCollection, getDocumentNamesFromPath};