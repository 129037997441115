import React from 'react';
import './BestPracticesGuide.css'; // Import the CSS file

const BestPractices = () => {
  return (
    <div className="bp-container">
      <h1 className="bp-header">Animation Best Practices & Guidelines</h1>

      <section className="bp-section">
        <h2 className="bp-section-title">Layer Parenting</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> Layer parenting is not supported. Do not parent layers to other layers or to null objects. This feature is currently not supported, but we plan to support it in the future.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Shape Layers</h2>
        <p className="bp-section-text">
          We recommend placing each shape on its own layer for optimal performance. However, multiple shapes within a single shape layer are supported.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Layer Effects</h2>
        <p className="bp-section-text">
          The only supported layer effect for animation is <strong className="bp-section-note">"Generate &gt; Fill"</strong>. Please avoid using any other layer effects. Individual shapes also support color fill animations.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Layer In and Out Points</h2>
        <p className="bp-section-text">
          We recommend setting all layers' in and out points to traverse the entire length of the composition. This ensures consistent behavior across your animation.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">3D Layers</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> 3D layers are currently not supported.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Coordinate Separation</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Warning:</strong> Separating layers by X and Y coordinates will cause the spec to crash. Avoid separating layers in this manner.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Spatial Interpolation</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> Animating along curved paths (spatial interpolation) is not currently supported. We plan to support this feature in the future.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Shape Layer Groups</h2>
        <p className="bp-section-text">
          Groups within shape layers are partially supported. We are working on fully supporting recursion through sub-shape layer groups, but this is not yet available. Sub-shapes on the root parent layer work, but groupings within sub-layers do not.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Anchor Point Animation</h2>
        <p className="bp-section-text">
          Animation of anchor points (transform origins) is not currently supported. However, offset anchor points that are not animated are supported.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Gradient Animations</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> Gradient animations are not currently supported, but they will be a "fast follow" in future updates.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Drop Shadows</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> Drop shadows are currently not supported, but they are on the roadmap.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Trim Path Animations</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> Trim path animations are currently not supported, but will be investigated after gradient animations.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Path Animation Keyframes</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Note:</strong> Path animation keyframes are currently not supported but are on the roadmap.
        </p>
      </section>

      <section className="bp-section">
        <h2 className="bp-section-title">Raster and Video Files</h2>
        <p className="bp-section-text">
          <strong className="bp-section-warning">Warning:</strong> Video files and any raster-based image sequences are not supported. Please avoid using these types of assets in your animations.
        </p>
      </section>
    </div>
  );
};

export default BestPractices;
