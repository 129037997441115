import React, { useState, useEffect, useRef } from 'react';
import { createSubFolder, getDocumentsInCollection } from '../../HelperFunctions/firestoreHelpers';
import './Search.css';
import listIcon from '../../Images/Icons/list_view.svg';
import gridIcon from '../../Images/Icons/grid_view.svg';
import newFolderIcon from '../../Images/Icons/newfolder.svg';

function SearchInput({ specArray, onFilteredArrayUpdate, createNewFolder, pathAry, curFolder, triggerRerender }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSpecArray, setFilteredSpecArray] = useState(specArray);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [listDisplay, setListDisplay] = useState("grid");
  const [submitted, setSubmitted] = useState(false);
  const inputRef = useRef(null);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSubmitted(false)
    setSearchTerm(term);
    if (term === '') {
      setFilteredSpecArray(specArray);
      onFilteredArrayUpdate(specArray);
    } else {
      const filtered = [...specArray].filter(spec => spec.name.toLowerCase().includes(term.toLowerCase()));
      setFilteredSpecArray(filtered);
      onFilteredArrayUpdate(filtered);
    }
    setActiveIndex(-1);
  };

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: // enter key
        if (activeIndex >= 0) {
          setSearchTerm(filteredSpecArray[activeIndex].name);
          onFilteredArrayUpdate([filteredSpecArray[activeIndex]]);
          setFilteredSpecArray([filteredSpecArray[activeIndex]]);
          setActiveIndex(-1);
          setSubmitted(true)
        }
        break;
      case 38: // up arrow key
        setActiveIndex(activeIndex - 1);
        break;
      case 40: // down arrow key
        setActiveIndex(activeIndex + 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const createAndUpdateFolders=async()=>{
    let subfolder_path=await createSubFolder(curFolder.path, { name: 'Untitled Folder', type: curFolder.type, permissions: [], isRoot:false, tenent: curFolder.tenent }); 
    let subcollectionPath=await getDocumentsInCollection(subfolder_path)
    
    triggerRerender(curFolder)
  }

  return (
    <div className="search-input-container" >
      {/* <div id="list-display-container">
        {listDisplay==="grid" ?
            <div  onClick={()=>{setListDisplay('list')}}>
               <img src={listIcon} style={{width: '36px', height:'50px', marginRight: '10px', opacity:.8}} alt="listIcon"></img>
            </div>
            :
            <div onClick={()=>{setListDisplay('grid')}}>
                <img style={{width: '36px', height:'50px', marginRight: '10px', opacity:.8}} src={gridIcon} alt="gridIcon"></img>
            </div>
        }
        
       
       
      </div> */}
      {curFolder !==null ?
        <div
          className="primary-btn"
          onClick={createAndUpdateFolders}
          style={{ display: 'flex', placeContent: 'center', marginTop: '0px', marginRight: '10px', paddingTop: '4px', paddingBottom: '4px', borderRadius: '20px' }}
        >
          <img src={newFolderIcon} style={{ height: '20px', marginRight: '6px', marginTop: '6px' }} alt="newFolderIcon"></img>
          <div style={{ color: 'white', marginTop: '8px' }}>New Folder</div>
        </div> :
        <div
          className="primary-btn"
          onClick={() => { createNewFolder({ name: 'Untitled Folder', type: "Private", permissions: [], isRoot: true }) }}
          style={{ display: 'flex', placeContent: 'center', marginTop: '0px', marginRight: '10px', paddingTop: '4px', paddingBottom: '4px', borderRadius: '20px' }}
        >
          <img src={newFolderIcon} style={{ height: '20px', marginRight: '6px', marginTop: '6px' }} alt="newFolderIcon"></img>
          <div style={{ color: 'white', marginTop: '8px' }}>New Folder</div>
        </div>
      }


      <input
        type="text"
        id="search-input-collections"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        placeholder="Search by name"
        ref={inputRef}
      />
      {filteredSpecArray.length > 0 && (
        <ul className="search-results">
          {searchTerm !== "" && !submitted ? filteredSpecArray.map((spec, index) => (
            <li
              key={spec.spec_id}
              className={index === activeIndex ? 'active' : ''}
              onClick={() => {
                setSearchTerm(spec.name);
                onFilteredArrayUpdate([spec]);
                setFilteredSpecArray([spec]);
                setActiveIndex(-1);
              }}
            >
              {spec.name}
            </li>
          )) : null}
        </ul>
      )}
    </div>
  );
}

export default SearchInput;
