import { keys } from 'lodash';
import React from 'react';
import './animatedProperties.css';
import PlayIcon from '../Images/Icons/play.svg';
import PauseIcon from '../Images/Icons/pause.svg';
import keyframeIcon from '../Images/Icons/keyframe.svg';
class AnimationTimeline extends React.Component {
    constructor(props) {
        console.log(props.time)
        super(props);
        this.state = {
            layers: [],
            error: false,
            totalFrames: 0,
            layerMap: {
                0: "Precomp",
                1: "Solid",
                2: "Image",
                3: "Null",
                4: "Shape",
                5: "Text",
            }
        };
    }

    componentDidMount() {
        const { animationData } = this.props;
        if (!animationData || !animationData.layers) {
            this.setState({
                error: true
            });
            return;
        }
        this.setState({
            layers: animationData.layers,
            totalFrames: animationData.op - animationData.ip,
            fps: animationData.fr,
        });
    }

    render() {
        const { error, layers } = this.state;
        if (error) {
            return <div>Error: animation data is not available.</div>;
        }
        return (
            <div className="animation-timeline">
                <div style={{ display: 'grid', gridTemplateColumns: '96px 1fr', marginRight: '0px', marginBottom: '16px' }}>
                    <div style={{ display: 'flex' }} onClick={this.props.togglePlay}>
                        {this.props.isPlaying ?
                            <img src={PauseIcon} alt="playIcon"></img> : <img src={PlayIcon} alt="playIcon"></img>
                        }
                        <div style={{ color: 'white', height: '100%', marginLeft: '14px', display: 'grid', placeContent: 'center' }}>{(this.props.time).toFixed(2).replace(/\.?0+$/, '') + "s"}</div>
                    </div>
                    <input
                        id="playhead"
                        type="range"
                        min={0}
                        max={this.props.animDuration}
                        step={0.01}
                        value={this.props.time}
                        onChange={this.props.handleTimeChange}
                    />
                </div>

                {layers.length > 0 && layers?.map(layer => {
                    if (!layer.ks || !layer.ks.o) {

                        return null;
                    }
                    const keyframes =
                        typeof layer.ks.o.k === 'number'
                            ? [layer.ks.o.k]
                            : Array.isArray(layer.ks.o.k)
                                ? layer.ks.o.k
                                : [];
                    return (
                        <div className="timeline-layer" onClick={() => { this.props.updateCurLayer(layer) }} key={layer.nm} style={{ width: layer.op < this.props.totalFrames ? (layer.op / this.props.totalFrames) * 100 + "%" : "100%", marginLeft: (layer.ip / this.props.totalFrames) * 100 }}>
                            <div className="timeline-layer-name">{layer.nm + "_" + layer.ind}</div>
                            {layer?.ks?.a?.a === 1 ?
                                <div className="timeline-anim-container">
                                    <div className="layer-parameter">Anchor Point</div>
                                    <div className="keyframe-line-container">
                                        <div className="keyframeline" style={{ position: 'relative', backgroundColor: '#F2CC60', marginLeft: layer.ip + "px", marginRight: layer.op / this.props.totalFrames }}>
                                            {layer?.ks?.a?.k?.map((positionKey, idx) => {

                                                return (
                                                    <div className="keyframe" key={idx} style={{ position: 'absolute', left: (positionKey.t / this.props.totalFrames) * 100 + "%" }} >

                                                        <div className='keyframe-duration' style={{ marginLeft: idx < layer.ks.a.k.length - 1 ? '0px' : '-16px' }}>
                                                            {(positionKey.t / this.props.frameRate).toFixed(2).replace(/\.?0+$/, '') + "s"}
                                                        </div>
                                                        <img className="keyframeIcon" src={keyframeIcon} alt="keyframe"></img>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {layer?.ks?.p?.a === 1 ?
                                <div className="timeline-anim-container">
                                    <div className="layer-parameter">Position</div>
                                    <div className="keyframe-line-container">
                                        <div className="keyframeline" style={{ position: 'relative', backgroundColor: '#3A96DD', marginLeft: layer.ip + "px", marginRight: layer.op / this.props.totalFrames }}>
                                            {layer?.ks?.p?.k?.map((positionKey, idx) => {
                                                const nextKeyframe = layer?.ks?.p?.k?.[idx + 1];
                                                const duration = nextKeyframe ? (nextKeyframe.t - positionKey.t) / this.props.frameRate : null;
                                                return (
                                                    <div className="keyframe" key={idx} style={{ position: 'absolute', left: (positionKey.t / this.props.totalFrames) * 100 + "%" }}>
                                                        <div className='keyframe-duration' style={{ marginLeft: idx < layer.ks.p.k.length - 1 ? '0px' : '-16px' }}>
                                                            {(positionKey.t / this.props.frameRate).toFixed(2).replace(/\.?0+$/, '') + "s"}
                                                        </div>
                                                        <img className="keyframeIcon" src={keyframeIcon} alt="keyframe"></img>
                                                        {/* {duration && (
                                                            <div className='keyframe-duration' style={{ marginLeft: (duration*100)+"%", marginTop: '8px'}}>
                                                                {duration.toFixed(2).replace(/\.?0+$/, '') + "s"}
                                                            </div>
                                                        )} */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {layer?.ks?.s?.a === 1 ?
                                <div className="timeline-anim-container">
                                    <div className="layer-parameter">Scale</div>
                                    <div className="keyframe-line-container">
                                        <div className="keyframeline" style={{ position: 'relative', backgroundColor: '#1CB86D', marginLeft: layer.ip + "px", marginRight: layer.op / this.props.totalFrames }}>
                                            {layer?.ks?.s?.k?.map((scaleKey, idx) => {
                                                return (
                                                    <div className="keyframe" style={{ position: 'absolute', left: (scaleKey.t / this.state.totalFrames) * 100 + "%" }} >
                                                        <div className='keyframe-duration' style={{ marginLeft: idx < layer.ks.s.k.length - 1 ? '0px' : '-40px' }}>
                                                            {(scaleKey.t / this.props.frameRate).toFixed(2).replace(/\.?0+$/, '') + "s"}
                                                        </div>
                                                        <img className="keyframeIcon" src={keyframeIcon} alt="keyframe"></img>

                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div> : null
                            }
                            {layer?.ks?.r?.a === 1 ?
                                <div className="timeline-anim-container">
                                    <div className="layer-parameter">Rotation</div>
                                    <div className="keyframe-line-container">
                                        <div className="keyframeline" style={{ position: 'relative', backgroundColor: '#553098', marginLeft: layer.ip + "px", marginRight: layer.op / this.props.totalFrames }}>
                                            {layer?.ks?.r?.k?.map((rotationKey, idx) => {
                                                return (
                                                    <div className="keyframe" style={{ position: 'absolute', left: (rotationKey.t / this.state.totalFrames) * 100 + "%" }} >
                                                        <div className='keyframe-duration' style={{ marginLeft: idx < layer.ks.r.k.length - 1 ? '0px' : '-40px' }}>
                                                            {(rotationKey.t / this.props.frameRate).toFixed(2).replace(/\.?0+$/, '') + "s"}
                                                        </div>
                                                        <img className="keyframeIcon" src={keyframeIcon} alt="keyframe"></img>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div> : null
                            }
                            {layer?.ks?.o?.a === 1 ?
                                <div className="timeline-anim-container">
                                    <div className="layer-parameter">Opacity</div>
                                    <div className="keyframe-line-container">
                                        <div className="keyframeline" style={{ position: 'relative', backgroundColor: '#DA3633', marginLeft: layer.ip + "px", marginRight: layer.op / this.props.totalFrames }}>
                                            {layer?.ks?.o?.k?.map((opacityKey, idx) => {
                                                return (
                                                    <div className="keyframe" style={{ position: 'absolute', left: (opacityKey.t / this.state.totalFrames) * 100 + "%" }} >
                                                        <div className='keyframe-duration' style={{ marginLeft: idx < layer.ks.o.k.length - 1 ? '0px' : '-40px' }}>
                                                            {(opacityKey.t / this.props.frameRate).toFixed(2).replace(/\.?0+$/, '') + "s"}
                                                        </div>
                                                        <img className="keyframeIcon" src={keyframeIcon} alt="keyframe"></img>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div> : null
                            }
                            {layer?.shapes?.map((shape) => {
                                if (shape.ty === "tm") {
                                    return (
                                        <div className="layer-parameter">Trim Path</div>
                                    )
                                }

                            })

                            }

                        </div>
                    );
                })}
                <div style={{ height: '300px' }}></div>
            </div>
        );
    }
}

export default AnimationTimeline;
