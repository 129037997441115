import React, { useState, useEffect } from 'react';
import './signup.css';
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import firebaseConfig from '../firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const RegistrationForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [error, setError] = useState('');
  const [company, setCompany] = useState("Microsoft");
  const [tenant, setTenant] = useState("uTRz8ofJ5AukTSSlKuqr");
  const [isValid, setIsValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    validateForm();
  }, [firstName, lastName, email, phone, password]);

  const handleSubmit = (e) => {
    e.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        sendEmailVerification(auth.currentUser)
          .then(() => {
            const createUser = async () => {
              try {
                await updateProfile(auth.currentUser, {
                  displayName: firstName + " " + lastName
                });
                const userRef = doc(db, "users", auth.currentUser.uid);
                await setDoc(userRef, {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone: phone,
                  tenent: tenant,
                  company: company,
                  profile_pic: 'initials',
                  uid: auth.currentUser.uid,
                  join_date: new Date()
                });

                console.log("Document written with ID: ", auth.currentUser.uid);
              } catch (e) {
                console.error("Error Creating User: ", e);
              }
            };
            createUser();

            setIsSubmitted(true);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/email-already-in-use") {
          setErrorMsg("An account with this email already exists!");
        }
        console.log(error);
      });
    console.log('Registration submitted');
  };

  const validateForm = () => {
    const isEmailValid = email.endsWith('@microsoft.com');
    setIsValid(
      firstName.length > 0 &&
      lastName.length > 0 &&
      isEmailValid &&
      phone.length > 0 &&
      password.length > 0
    );

    if (!isEmailValid) {
      setError('Only @microsoft.com email addresses are allowed.');
    } else {
      setError('');
    }
  };

  const handleCompanyChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const companyName = selectedOption.value;
    const tenantId = selectedOption.getAttribute('data-tenant-id');

    setCompany(companyName);
    setTenant(tenantId);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateForm();
  };

  return (
    <div className="register-wrapper">
      <div className='content-wrapper'>
        <h1 style={{ color: '#f4f4f4', textAlign: 'center' }}>MotionSpec.io</h1>
        {isSubmitted ? (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ color: 'white' }}>Thank you for signing up!</h2>
            <p style={{ color: 'white' }}>Please check your email to verify your account.</p>
          </div>
        ) : (
          <div className="registration-form">
            <h2>Create Account</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="first-name">First Name:</label>
                <input
                  type="text"
                  id="first-name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    validateForm();
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="last-name">Last Name:</label>
                <input
                  type="text"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    validateForm();
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                  required
                />
                {error && <p>{error}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    validateForm();
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validateForm();
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">Company:</label>
                <select style={{ height: '28px', borderRadius: '4px' }} onChange={handleCompanyChange}>
                  <option default value="Microsoft" data-tenant-id="uTRz8ofJ5AukTSSlKuqr">Microsoft</option>
                </select>
              </div>
              <button type="submit" disabled={!isValid}>Register</button>
              {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
