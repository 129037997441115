import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './motionspec_loader.json';

const SpecLoader = (props) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      height: 500,
      width: 500,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <div style={{display: 'grid', placeContent:'center', height: '100vh'}}>
   
        <div style={{width: '400px', height:'400px'}} ref={container}></div>
        {props.subText ? 
        <h3 style={{ color: 'white', textAlign:'center', marginTop:'-160px' }}>{props.subText}</h3>:null
        }
        
    </div>
    
  ) 
  
};

export default SpecLoader;
