import { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import { onAuthStateChanged } from "firebase/auth";
import SpecLoader from "../Loaders/SpecLoader";

const ProtectedRoutes = ({ children }) => {
  const [user, setUser] = useState(null);
 
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);    
    });
    return unsubscribe;
  }, []);

 
  return (
    <div>
    { user ? 
        <Outlet></Outlet>:<SpecLoader/>
    }
    </div>
  );
};

export default ProtectedRoutes;
