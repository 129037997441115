import * as React from 'react';


function PricingPage() {
    // Paste the stripe-pricing-table snippet in your React component
    return (
        <div style={{postion:'relative', height:'100vh', paddingTop:'100px', display: 'block', backgroundColor:'#141414', padding:'auto', width:'100%'}}>
            <stripe-pricing-table
                pricing-table-id="prctbl_1MgER9Jrwq70HqjqA9L48An0"
                publishable-key="pk_live_51MgDhPJrwq70HqjqwDqaoIRuivSCtQa9NIOY37A5KXuyDJTs5yoSL1WW77BX7oe4bj00JuSJSlBmrza0GjNYFWXB00PLn7i8ep"
            >
            </stripe-pricing-table>
        </div>
    );
}

export default PricingPage;

