import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";


export default function LottieJSON(props) {
  const [formattedJSON, setFormattedJSON] = useState("");

  useEffect(() => {
    if (Object.keys(props.animationData).length > 0) {
      const jsonString = JSON.stringify(props.animationData, null, 2);
      setFormattedJSON(jsonString);
    }
  }, [props.animationData]);

  return (
    <div style={{ backgroundColor: "rgb(30, 30, 30)" }}>
      <SyntaxHighlighter language="json" style={vscDarkPlus}>
        {formattedJSON}
      </SyntaxHighlighter>
    </div>
  );
}
