import { useState, useEffect } from 'react';
import './ufd.css';

const FloatingUfdBanner = ({ msg }) => {
  const [isShowing, setIsShowing] = useState(true);

  

  return (
    <div className="floating-ufd-banner">
      {msg}
    </div>
  );
};

export default FloatingUfdBanner;
