import React, { useState, useCallback, useEffect } from 'react';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import LottiePreview from './LottiePreview/LottiePreview';
import { getFirestore, collection, setDoc, getPath, addDoc, getDoc, doc, query, getDocs, where } from "firebase/firestore";
import { getDocumentNamesFromPath, getDocumentsInCollection, getCurFolder } from '../HelperFunctions/firestoreHelpers'
import Folder from '../Folders/Folder';
import SearchInput from './Search/Search';
import chevronIcon from '../Images/Icons/chevron_right.svg'
import './collection.css';
import { pull } from 'lodash';

const Collection = (props) => {
  const [draggedJSON, setDraggedJSON] = useState('#666');
  const [specs, setSpecs] = useState([])
  const [folders, setFolders] = useState([])
  const [rerenderContent, setRerenderContent] = useState(false)
  const [curFolder, setCurFolder] = useState(null)
  const [pathAry, setPathAry] = useState([])
  const [filteredFolders, setFilteredFolders] = useState([])
  const [filteredSpecs, setFilteredSpecs] = useState([])
  const [collectionDisplay, setCollectionDisplay] = useState('grid');


  useEffect(() => {
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1)
    setCurFolderFromURL(pathname)
    getFolders(pathname);
    getSpecs(pathname);
  }, [rerenderContent])


  const setCurFolderFromURL=async(pathname)=>{
    console.log(pathname)
    if(pathname!=='home'){
      let curfol= await getCurFolder(pathname)
      console.log(curfol)
      setCurFolder(curfol)
    }else{
      setCurFolder(null)
    }
  
  }


  const getSpecs = async (path) => {
    setSpecs([])
    // Fetch specs from Firebase
    let q = null
    if (path!=="home") {
      console.log(path)
      let folder_id = path.split('/').pop()
      q = query(collection(db, "specs"), where("folder_id", "==", folder_id));     
    } else {
      //Get Root Level Specs
      q = query(collection(db, "specs"), where("uid", "==", props.userData.uid), where("folder_id","==","root"));
    }

    const querySnapshot = await getDocs(q);
    let specAry = [];
    querySnapshot.forEach((doc) => {
      if (Array.isArray(doc.data())) {
        setSpecs(doc.data());
      } else {
        specAry.push(doc.data());
      }
    });

    let sortedSpecs = sortByField(specAry, "data_uploaded", true)
    setSpecs(sortedSpecs);
    setFilteredSpecs(sortedSpecs)
    // console.log(specAry)
  };

  function sortByField(data, field, reverse = false) {
    return data.sort((a, b) => {
      let comparison = 0;
      if (a[field] < b[field]) {
        comparison = -1;
      }
      if (a[field] > b[field]) {
        comparison = 1;
      }
      return reverse ? comparison * -1 : comparison;
    });
  }


  const addSpecs = async (uploadedFile) => {
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1)
    let currentFolder={}
    let folderID=""
    if(pathname!=='home'){
      let currentFolder=await getCurFolder(pathname)
      folderID=currentFolder.folder_id
    }else{
      folderID="root"
    }
  
    // Send Spec to Firebase
    const docRef = await addDoc(collection(db, "specs"), {
      // spec_id: newId,
      name: uploadedFile.name,
      lottieData: uploadedFile.lottieData,
      uid: props.userData.uid,
      date_uploaded: new Date(),
      notes: "",
      folder_id: folderID,
      lottiePreviewBG: 'transparent',
      attachments: [],
      share_link: {},

    });

    console.log("Spec written with ID: ", docRef.id);

    const idAdded = await setDoc(doc(db, "specs", docRef.id), {
      spec_id: docRef.id,
      path:docRef.path
    }, { merge: true });
    
    getSpecs(pathname)
   
  };


  const createNewFolder = async (folder) => {
    // Send New Folder to Firebase
    const docRef = await addDoc(collection(db, "folders"), {
      name: folder.name,
      type: folder.type,
      creator_uid: props.userData.uid,
      date_created: new Date(),
      permissions: [props.userData.uid],
      isRoot: folder.isRoot,
    });

    const idAdded = await setDoc(doc(db, "folders", docRef.id), {
      folder_id: docRef.id,
      path: docRef.path,
    }, { merge: true });
    triggerRerender()
  };

  const createNestedFolder = async (parentFolder, folder) => {
    //Find the parent folder

    //Add to Subcollection



    // Send New Folder to Firebase
    const docRef = await addDoc(collection(db, "folders"), {
      name: folder.name,
      type: folder.type,
      creator_uid: props.userData.uid,
      date_created: new Date(),
      path: folder.path,
      permissions: [],
      parent: folder.parent
    });

    const idAdded = await setDoc(doc(db, "folders", docRef.id), {
      folder_id: docRef.id
    }, { merge: true });
    getFolders()
  };

  const getFolders = async (path) => {
    setFolders([])

    let q = null
    if (path!=="home") {
      //Get Folders within Folder
      let subfolders = []
      subfolders = await getDocumentsInCollection(path + '/subfolder')
      setFolders(subfolders)
      let prettyPath = await getDocumentNamesFromPath(path)
      setPathAry(prettyPath)
      return
    } else {
      //Get Root Level Folders Private version
      q = query(collection(db, "folders"), where("creator_uid", "==", props.userData.uid), where("isRoot", "==", true), where("type", "==", "Private")) ;
      const querySnapshot = await getDocs(q);
      let folderAry = [];

      querySnapshot.forEach((doc) => {
        if (Array.isArray(doc.data())) {
          setFolders(doc.data());
        } else {
          folderAry.push(doc.data());
        }
      });

      //Get Shared Folders
      q = query(collection(db, "folders"), where("tenent", "==", props.userData.tenent), where("isRoot", "==", true)) ;
      const querySnapshotShared = await getDocs(q);

      querySnapshotShared.forEach((doc) => {
       
        if (Array.isArray(doc.data())) {
          setFolders(doc.data());
        } else {
          folderAry.push(doc.data());
        }
      });

      let sortedFolders = sortByField(folderAry, "data_created", true)
      setFolders(sortedFolders);

      setFilteredFolders(sortedFolders)

    }

  };


  // const getFolderContents = async (folder_id) => {
  //   setSpecs([])
  //   // Fetch specs from Firebase
  //   const q = query(collection(db, "specs"), where("folder_id", "==", folder_id));
  //   const querySnapshot = await getDocs(q);
  //   let specAry = [];

  //   querySnapshot.forEach((doc) => {
  //     if (Array.isArray(doc.data())) {
  //       setSpecs(doc.data());
  //     } else {
  //       specAry.push(doc.data());
  //     }
  //   });

  //   let sortedSpecs = sortByField(specAry, "data_uploaded", true)
  //   setSpecs(sortedSpecs);
  //   setFilteredSpecs(sortedSpecs)
  //   console.log(specAry)
  // };


  const onDrop = useCallback(acceptedFiles => {
    const newLottieFiles = [...acceptedFiles].map(async file => {
      if (file.type === 'application/json') {
        const json = await file.text();
        return {
          name: file.name,
          lottieData: json,
        };
      } else {
        return {
          name: file.name,
          lottieData: URL.createObjectURL(file),
        };
      }
    });
    Promise.all(newLottieFiles).then(files => {
      files.forEach(file => {
        addSpecs(file);
      });
    });
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    const allFilesAreJson = Array.from(e.dataTransfer.items).every(
      (item) => item.kind === "file" && item.type === "application/json"
    );
    if (allFilesAreJson) {
      e.dataTransfer.dropEffect = "copy";
      setDraggedJSON('green')
    } else {
      e.dataTransfer.dropEffect = "none";
      setDraggedJSON('red')
    }
  };



  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.type === 'application/json') {
      onDrop([file]);
    }
  };

  const handleSearchUpdate = (filteredArray) => {
    setSpecs(filteredArray);
  };

  const handleListDisplayUpdate = (display) => {
    setCollectionDisplay(display)
  };

  const triggerRerender = async (folder) => {
  
    if (folder) {
      setCurFolder(folder)
      setRerenderContent(!rerenderContent)

    } else {
      setCurFolder(null)
      setRerenderContent(!rerenderContent)
    }

  }

  async function getFolderName(folder_id) {
    let res = await getDoc(doc(db, "folders", folder_id));
    console.log(res.data().name);
    return res.data().name;
  }


  return (
    <div>
      
      
      <SearchInput
        createNewFolder={createNewFolder}
        specArray={filteredSpecs}
        pathAry={pathAry}
        curFolder={curFolder}
        triggerRerender={triggerRerender}
        onFilteredArrayUpdate={handleSearchUpdate}
        onListDisplayUpdate={handleListDisplayUpdate}
      />
      <div style={{ display: 'flex' }}>
        <a style={{ color: "white", marginBottom: '14px', fontSize: '16px' }} href="/home" onClick={() => { setCurFolder(null) }}>My Specs</a>
        {pathAry && pathAry.length > 0 ? pathAry.map((folder, index) => (
          <div style={{ display: 'flex', marginLeft: '4px', }}>
            <img src={chevronIcon} style={{ height: "24px" }} alt="chevron"></img>
            <a key={index} href={"/" + folder.path} style={{ color: "white", marginBottom: '14px', fontSize: '16px' }}>{folder.name}</a>
          </div>
        )) : null}
      </div>
      <div className="lottie-collection" style={{ display: collectionDisplay === "grid" ? 'grid' : 'block' }}>
        {/* Render the upload button */}
        <div
          className="lottie-upload"
          style={{ borderColor: draggedJSON }}
          onDragOver={handleDragOver}
          onDragLeave={() => { setDraggedJSON('#666') }}
          onDrop={handleDrop}
        >
          <label id="uploadLabel" htmlFor="fileInput">Upload a lottie .json file</label>
          <input
            id="fileInput"
            type="file"
            accept=".json"
            onChange={(e) => onDrop(e.target.files)}
          />
        </div>
        {folders.length > 0 ? folders.map((folder, index) => (
          <div key={index} style={{ position: 'relative'}}>
            <Folder
              folder={folder}
              triggerRerender={triggerRerender}
            ></Folder>
          </div>
        )) : null}
        {specs.length > 0 ? specs.map((spec, index) => (
          <div key={index} style={{ position: 'relative', overflow: 'hidden', height: '300px' }}>
            {spec.lottieData ?
              <LottiePreview
                spec={spec}
                index={index}
                triggerRerender={triggerRerender}
                collectionDisplay={collectionDisplay}
                getSpecs={getSpecs}
              /> : null
            }
          </div>
        )) : null}
        <div style={{height:'300px'}}></div>
      </div>
    </div>
  );

};

export default Collection;
