import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import lottie_api from "lottie-api";
import { app, auth, db } from './Auth/firebaseApp/firebaseApp';
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore";
import './spec.css';
import Header from './Header/Header';
import CodeHeader from "./CodeConversion/CodeHeader/CodeHeader";
import LottieToCSS from "./CodeConversion/CSS/LottieToCSS";
import LottieToFluent from "./CodeConversion/Fluent/LottieToFluent";
import LottieToReact from "./CodeConversion/React/LottieToReact";
import LottieToHTML from "./CodeConversion/HTML/LottieToHTML";
import LottieToPrettyJSON from './CodeConversion/JSON/LottieToPrettyJSON'
import AnimationTimeline from "./Timeline/AnimationTimeline";
import hotkeys from 'hotkeys-js';
import ColorPickerIcon from './Images/Icons/colorPickerIcon.svg';
import LiveHTML from './LiveCode/LiveHTML';
import CTI from "./Timeline/CTI/CTI";
import PropertiesPanel from './PropertiesPanel/PropertiesPanel';
import magnifyIcon from './Images/Icons/magnify.svg';
import demagnifyIcon from './Images/Icons/demagnify.svg';

const Spec = (props) => {
    const [lottieData, setLottieData] = useState(null);
    const [codePreviewMain, setCodePreviewMain] = useState('fluent');
    const [codePreviewSecondary, setCodePreviewSecondary] = useState('json');
    const [codeSplit, setCodeSplit] = useState(true);
    const [firstRun, setFirstRun] = useState(true);
    const [layout, setLayout] = useState(true);
    const [specID, setSpecID] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [totalFrames, setTotalFrames] = useState(null);
    const [frameRate, setFrameRate] = useState(null);
    const [animDuration, setAnimDuration] = useState(null);
    const [openLiveProto, setOpenLiveProto] = useState(false);
    const [orginalLottieData, setOriginalLottieData] = useState(null);
    const [isSolo, setIsSolo] = useState(false);
    const [layerInfo, setLayerInfo] = useState(null);
    const [curLayer, setCurLayer] = useState(null);
    const [playerLoaded, setPlayerLoaded] = useState(null);
    const [selectedLayerIndex, setSelectedLayerIndex] = useState(false);
    const [prettyAnim, setPrettyAnim] = useState(null);
    //const [animation, setAnimation] = useState(null);
    const [playhead, setPlayhead] = useState(null);
    const [currentFrame, setCurrentFrame] = useState(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [time, setTime] = useState(0);
    const [notes, setNotes] = useState("")
    const [reactCode, setReactCode] = useState(null);
    const [cssCode, setCSSCode] = useState(null);
    const [fluentCode, setFluentCode] = useState(null);
    const [htmlCode, setHTMLCode] = useState(null);
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [lottiePreviewBG, setLottiePreviewBG] = useState('transparent');
    const animationContainer = useRef(null);
    const animation = useRef(null);
    const currentTime = useRef(0);



    useEffect(() => {
        //Pull Spec ID From URL params
        const url = window.location.href;
        const spec_id = url.split('/').pop();
        if (props.lottieData) {
            setSpecID(props.setSpecID)
            setNotes(props.notes)
            setLottieData(JSON.parse(props.lottieData))
        } else {
            setSpecID(spec_id)
            getSpec(spec_id)
        }
    }, [])

    const handleEnterFrame = (event) => {
        var newCurrentTime = animation.current.currentFrame / animation.current.frameRate;

        setTime(newCurrentTime)
    };

    // useEffect(() => {
    //     if (lottieData) {
    //         let animData = prependToLayerNames(lottieData)
    //         setLottieData(animData)
    //         animation.current = lottie.loadAnimation({
    //             container: animationContainer.current,
    //             renderer: "svg",
    //             loop: false,
    //             autoplay: isPlaying,
    //             animationData: animData,
    //             rendererSettings: {
    //                 preserveAspectRatio: "xMidYMid slice"
    //             }
    //         });

    //         animation.current.addEventListener("DOMLoaded", () => {

    //             setTimeout(() => {
    //                 setPlayerLoaded(true);
    //             }, 5000); // 2-second delay
    //         });



    //         animation.current.addEventListener("complete", () => {
    //             // Reset to frame 0 and play again
    //             animation.current.goToAndStop(0, true);
    //             animation.current.play();
    //         });

     

    //         // Create Lottie API instance
    //         const animationAPI = lottie_api.createAnimationApi(animation.current);

    //         //   // Example: Get the key path for the 'double' layer
    //         //   const keyPath = animationAPI.getKeyPath('Shape Layer 1,Transform,Position');
    //         //   const positionProperty = keyPath.getPropertyAtIndex(0).getValue();

    //         //   console.log('Position of double layer:', positionProperty);

    //         //   // Convert the position to a point relative to the container
    //         //   const containerPoint = animationAPI.fromContainerPoint(positionProperty);
    //         //   console.log('Container Point:', containerPoint);

    //         //   // Recalculate size if necessary
    //         //   animationAPI.recalculateSize();

    //         //   // Get the layer data directly from the animation data
    //         //   const doubleLayer = lottieData.layers.find(layer => layer.nm === 'double');
    //         //   if (doubleLayer) {
    //         //     const shapes = doubleLayer.shapes || [];
    //         //     shapes.forEach(shape => {
    //         //       if (shape.ty === 'gr') {
    //         //         shape.it.forEach(item => {
    //         //           if (item.ty === 'tr') { // Transform group
    //         //             console.log('Transform group found', item);
    //         //           } else if (item.ty === 'el') { // Ellipse
    //         //             console.log('Ellipse size:', item.s.k);
    //         //           } else if (item.ty === 'rc') { // Rectangle
    //         //             console.log('Rectangle size:', item.s.k);
    //         //           } else if (item.ty === 'sr') { // Star
    //         //             console.log('Star size:', item.s.k);
    //         //           }
    //         //         });
    //         //       }
    //         //     });
    //         //   }
    //         // let positionProperty = animationAPI.getKeyPath('Shape Layer 1,Transform,Position').getPropertyAtIndex(0).getValue();;
    //         // console.log(positionProperty)

    //         // const containerPoint = animationAPI.fromContainerPoint(positionProperty);

    //         // console.log(containerPoint); 

    //         animation.current.addEventListener('enterFrame', handleEnterFrame);

    //         hotkeys('space', function (event, handler) {
    //             event.preventDefault();

    //             if (animation.current.isPaused) {
    //                 animation.current.play();
    //                 setIsPlaying(true);
    //             } else {
    //                 setIsPlaying(false);
    //                 animation.current.pause();
    //             }
    //         });

    //         if (firstRun) {
    //             let duration = animation.current.getDuration();
    //             const totalFrames = duration * animation.current.frameRate;
    //             setFrameRate(animation.current.frameRate);
    //             setTotalFrames(totalFrames);
    //             setOriginalLottieData(lottieData);
    //             setAnimDuration(duration);
    //             setCurLayer(lottieData.layers);
    //         }

    //         setFirstRun(false);
    //     }
    // }, [lottieData]);

    useEffect(() => {
        if (lottieData) {
            let animData = prependToLayerNames(lottieData);
            setLottieData(animData);
            
            // Load the animation
            animation.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: "svg",
                loop: false,
                autoplay: isPlaying,
                animationData: animData,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            });
    
            // Set up event listener for when the animation completes
            animation.current.addEventListener("complete", () => {
                // Reset to frame 0
                animation.current.goToAndStop(0, true);
                
                // Calculate the timeout duration (animation duration + 2 seconds)
                const timeoutDuration = (animation.current.getDuration() * 1000) + 3000;
                setIsPlaying(false)
                // Set `setPlayerLoaded(true)` after the timeout
                setTimeout(() => {
                    setPlayerLoaded(true);
                    
                }, timeoutDuration);
            });
    
            // Set up other event listeners and initialization logic
            animation.current.addEventListener("DOMLoaded", () => {
                // No additional logic here since `setPlayerLoaded(true)` will be handled by the complete event
            });
    
            animation.current.addEventListener('enterFrame', handleEnterFrame);
    
            hotkeys('space', function (event, handler) {
                event.preventDefault();
    
                if (animation.current.isPaused) {
                    animation.current.play();
                    setIsPlaying(true);
                } else {
                    setIsPlaying(false);
                    animation.current.pause();
                }
            });
    
            if (firstRun) {
                let duration = animation.current.getDuration();
                const totalFrames = duration * animation.current.frameRate;
                setFrameRate(animation.current.frameRate);
                setTotalFrames(totalFrames);
                setOriginalLottieData(lottieData);
                setAnimDuration(duration);
                setCurLayer(lottieData.layers);
                setFirstRun(false);
            }
    
            // Clean up when the component unmounts or when lottieData changes
            return () => {
                if (animation.current) {
                    animation.current.destroy();
                }
            };
        }
    }, [lottieData]);
    
    
    
    


    const openProtoWindow = () => {
        LiveHTML(htmlCode, cssCode)
    }
    const prependToLayerNames = (json) => {
        if (typeof json === 'object' && json !== null) {
            for (let key in json) {
                if (key === 'nm' && typeof json[key] === 'string') {
                    // Prepend a '#' to the nm field if it doesn't already start with it
                    if (!json[key].startsWith('#')) {
                        json[key] = '#' + json[key];
                    } else {
                        let count = 0;
                        while (json[key].startsWith('#', count)) {
                            count++;
                        }
                        json[key] = '#' + json[key].slice(count);
                    }

                    // Apply cleanName to nm field without the leading '#'
                    const cleanedName = cleanName(json[key].substring(1));

                    // Append the ind or ix value to nm and ln fields
                    if ('ind' in json) {
                        json[key] = '#' + cleanedName + "_" + json['ind'];
                        json['ln'] = cleanedName + "_" + json['ind'];
                    } else if ('ix' in json) {
                        json[key] = '#' + cleanedName + "_" + json['ix'];
                        json['ln'] = cleanedName + "_" + json['ix'];
                    }
                } else if (Array.isArray(json[key])) {
                    json[key].forEach(item => prependToLayerNames(item));
                } else if (typeof json[key] === 'object') {
                    prependToLayerNames(json[key]);
                }
            }
        }
        return json; // Return the updated json
    }

    // cleanName function
    const cleanName = (name) => {
        return name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');
    }




    const getSpec = async (spec_id) => {
        // Fetch specs from Firebase
        const q = query(collection(db, "specs"), where("spec_id", "==", spec_id));
        const querySnapshot = await getDocs(q);
        let specAry = [];
        querySnapshot.forEach((doc) => {
            if (Array.isArray(doc.data())) {
                setLottieData(JSON.parse(doc.data()[0].lottieData))


            } else {
                setLottieData(JSON.parse(doc.data().lottieData))
                setNotes(doc.data().notes)
                setLottiePreviewBG(doc.data().lottiePreviewBG)
            }
        });
        setLottieData(JSON.parse(specAry.lottieData));
    };




    const togglePlay = () => {
        if (animation) {
            if (isPlaying) {
                animation.current.pause()
            } else {
                animation.current.play()
            }
            setIsPlaying(!isPlaying);

        }
    };


    const handleTimeChange = (e) => {
        setTime(e.target.value);
        setIsPlaying(false)
        animation.current.goToAndStop(e.target.value * lottieData.fr, true);
    };


    const deleteNode = React.useCallback(() => {
        // logic here
    }, [])

    const handlers = {
        DELETE_NODE: deleteNode
    };


    const updateCurLayer = (layer) => {
        setSelectedLayerIndex(layer.ix)
        setCurLayer(layer)
    }

    const handleLayoutChange = () => {

        setLayout(!layout)

    }

    const zoomAnimation = (zoomIncrement) => {

        let curZoom = zoom
        let newZoom = curZoom + zoomIncrement
        if (newZoom > 0) {
            setZoom(newZoom)
        }

    }

    const updateReactCode = (code) => {
        setReactCode(code)
    }

    const updateFluentCode = (code) => {
        //console.log(code)
        setFluentCode(code)
    }


    const updateHTMLCode = (code) => {
        setHTMLCode(code)
    }

    const updateCSSCode = (code) => {
        setCSSCode(code)
    }

    const changePreiveBgColor = (color) => {
        setLottiePreviewBG(color)
    }

    const handleCodePreviewChange = (code, bucket) => {
        if (bucket === "main") {
            setCodePreviewMain(code)
        }
        if (bucket === "secondary") {
            setCodePreviewSecondary(code)
        }


    }

    const handleCodeSplitChange = () => {
        setCodeSplit(!codeSplit)
    }

    const copyCodeToClipboard = (bucket) => {
        if (bucket === 'main') {
            if (codePreviewMain === 'html') {
                copyCode(htmlCode)
            }
            if (codePreviewMain === 'react') {
                copyCode(reactCode)
            }
            if (codePreviewMain === 'fluent') {
                copyCode(fluentCode)
            }
            if (codePreviewMain === 'css') {
                copyCode(cssCode)
            }
            if (codePreviewMain === 'json') {
                copyCode(JSON.stringify(lottieData))
            }
        }

        if (bucket === 'secondary') {
            if (codePreviewSecondary === 'html') {
                copyCode(htmlCode)
            }
            if (codePreviewSecondary === 'react') {
                copyCode(reactCode)
            }
            if (codePreviewSecondary === 'fluent') {
                copyCode(fluentCode)
            }
            if (codePreviewSecondary === 'css') {
                copyCode(cssCode)
            }
            if (codePreviewSecondary === 'json') {
                copyCode(JSON.stringify(lottieData))
            }
        }
    }

    const copyCode = (code) => {
        // console.log(code)
        navigator.clipboard.writeText(code)
            .then(() => {

                console.log('Code copied to clipboard successfully.');
            })
            .catch((error) => {
                console.error('Failed to copy code to clipboard:', error);
            });
    }

    return (


        <div>
            <Header switchLayout={handleLayoutChange} lottieData={lottieData} view_mode={props.view_mode} spec_id={specID} notes={notes} css={cssCode} react={reactCode} html={htmlCode} fluent={fluentCode} lottiePreviewBG={lottiePreviewBG}></Header>
            <div id="spec-main-container" >
                {lottieData ? (
                    <React.Fragment>
                        <div id="preview-container" style={{}}>
                            <div id="lottie-preview-color-picker" onClick={() => { setOpenColorPicker(!openColorPicker) }} style={{ height: openColorPicker ? '310px' : '26px' }}>
                                {!openColorPicker ?
                                    <img src={ColorPickerIcon} alt="color-picker-icon" style={{ width: '18px', height: '18px' }} /> :
                                    <div style={{ display: 'grid', placeContent: 'center', padding: '2px ' }}>
                                        <div style={{ display: 'grid', placeContent: 'center', height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#555555', marginBottom: '6px' }}>
                                            <img src={ColorPickerIcon} alt="color-picker-icon" style={{ width: '14px', height: '14px', }} />
                                        </div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: 'white', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("transparent") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#388BFD', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#388BFD") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#A371F7', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#A371F7") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#DB61A2', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#DB61A2") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#F85149', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#F85149") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#DB6D28', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#DB6D28") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#BB800A', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#BB800A") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#2EA043', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#2EA043") }}></div>
                                        <div style={{ height: '24px', width: '24px', borderRadius: '30px', backgroundColor: '#555555', marginBottom: '6px' }} onClick={() => { changePreiveBgColor("#555555") }}></div>
                                    </div>
                                }
                            </div>
                            <div className="magnifyControl">
                                <div onClick={() => { zoomAnimation(.2) }}>
                                    <img style={{ width: '16px', height: '16px' }} src={magnifyIcon} alt="magnify"></img>
                                </div>
                                <div onClick={() => { zoomAnimation(-.2) }}>
                                    <img style={{ width: '16px', height: '16px' }} src={demagnifyIcon} alt="magnify"></img>
                                </div>
                            </div>

                            <div id="lottie-preview" ref={animationContainer} style={{ transform: `scale(${zoom})`, backgroundColor: lottiePreviewBG }}>
                            </div>

                        </div>



                        <div id="code-preview">
                            <div style={{ position:'relative', width: codeSplit ? '50%' : '100%', height: 'inherit', borderRight: "1px solid #333333", overflow: 'auto', transition: 'all .3s cubic-bezier(0,0,0,1)' }}>

                              
                                <CodeHeader bucket="main" handleCodePreviewChange={handleCodePreviewChange} copyCodeToClipboard={copyCodeToClipboard} selected={codePreviewMain} handleCodeSplitChange={handleCodeSplitChange}></CodeHeader>
                                {codePreviewMain === 'fluent' && !playerLoaded ?
                                    <div className="loading-container">
                                        <div>Generating Animation Code</div>
                                        <div className="dots">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>:null
                                }
                                {codePreviewMain === 'fluent' && playerLoaded && document.getElementById("lottie-preview").hasChildNodes() ?
                                    <LottieToFluent animationData={lottieData} updateFluentCode={updateFluentCode} lottiePreviewBG={lottiePreviewBG}></LottieToFluent>
                                    : null
                                }
                                {/* {codePreviewMain === 'html' ?
                                    <LottieToHTML animationData={lottieData} updateHTMLCode={updateHTMLCode} css={cssCode} html={htmlCode} lottiePreviewBG={lottiePreviewBG}></LottieToHTML>
                                    : null
                                }
                                {codePreviewMain === "react" ?
                                    <LottieToReact animationData={lottieData} updateReactCode={updateReactCode} lottiePreviewBG={lottiePreviewBG}></LottieToReact>
                                    : null
                                }
                                {codePreviewMain === "css" ?
                                    <LottieToCSS animationData={lottieData} updateCSSCode={updateCSSCode}></LottieToCSS>
                                    : null
                                } */}
                                {codePreviewMain === "json" ?
                                    <LottieToPrettyJSON animationData={lottieData}></LottieToPrettyJSON>
                                    : null
                                }
                            </div>
                            {codeSplit ?
                                <div style={{ width: '50%', overflow: 'auto' }}>
                                    <CodeHeader bucket="secondary" handleCodePreviewChange={handleCodePreviewChange} selected={codePreviewSecondary} handleCodeSplitChange={handleCodeSplitChange}></CodeHeader>
                                    
                                    {codePreviewSecondary === 'fluent' ?
                                        <LottieToFluent animationData={lottieData} updateFluentCode={updateFluentCode} lottiePreviewBG={lottiePreviewBG}></LottieToFluent>
                                        : null
                                    }
                                    {/* {codePreviewSecondary === 'html' ?
                                        <LottieToHTML animationData={lottieData} updateHTMLCode={updateHTMLCode} lottiePreviewBG={lottiePreviewBG}></LottieToHTML>
                                        : null
                                    }
                                    {codePreviewSecondary === "react" ?
                                        <LottieToReact animationData={lottieData} updateReactCode={updateReactCode}></LottieToReact>
                                        : null
                                    }
                                    {codePreviewSecondary === "css" ?
                                        <LottieToCSS animationData={lottieData} updateCSSCode={updateCSSCode}></LottieToCSS>
                                        : null
                                    } */}
                                    {codePreviewSecondary === "json" ?
                                        <LottieToPrettyJSON animationData={lottieData}></LottieToPrettyJSON>
                                        : null
                                    }
                                </div> : null
                            }

                        </div>

                        <div id="bottom-container">
                            <AnimationTimeline
                                animationData={lottieData}
                                time={time}
                                togglePlay={togglePlay}
                                isPlaying={isPlaying}
                                updateCurLayer={updateCurLayer}
                                frameRate={frameRate}
                                totalFrames={totalFrames}
                                animDuration={animDuration}
                                handleTimeChange={handleTimeChange}
                            ></AnimationTimeline>
                        </div> : null

                    </React.Fragment>
                ) : null
                }

            </div>
        </div>

    );
};

export default Spec;


