import React, { useState, useEffect } from "react";
import FolderIcon from '../Images/Icons/folder.svg';
import trashIcon from '../Images/Icons/trash.svg';
import lockIcon from '../Images/Icons/white_lock.svg';
import downArrow from '../Images/Icons/downarrow.svg';
import openIcon from '../Images/Icons/open.svg';
import InviteModal from '../InviteToFolder/InviteToFolder'
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../Auth/firebaseApp/firebaseApp';
import { setDoc, doc, deleteDoc, collection, where, getDocs } from "firebase/firestore";
import Avatar from "../Avatar/Avatar";
import addPerson from '../Images/Icons/addperson.svg'
import { getDocumentsInCollection, getUserById } from "../HelperFunctions/firestoreHelpers";
import { useNavigate } from 'react-router-dom';

import './Folder.css'
const Folder = ({ folder, triggerRerender }) => {
    const [folderName, setFolderName] = useState(null)
    const [folderDD, setShowFolderDD] = useState(false)
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [access, setAccess] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        setFolderName(folder.name)
        getFolderAvatars(folder)

    }, [folder])

    const getFolderAvatars = async (folder) => {
        let avatarAry = [];
        await Promise.all(
            folder.permissions.map(async (permission) => {
                let person = await getUserById(permission);
                // console.log(person);
                avatarAry.push(person);
            })
        );
        setAccess(avatarAry);
    };


    const handleFolderNameChange = (e) => {
        setFolderName(e.target.value)
    }

    const updateFolderName = async (path) => {
        const docRef = doc(db, path);
        let res = await setDoc(docRef, {
            name: folderName
        }, { merge: true });
        triggerRerender()
    };

    const openFolder = async (folder) => {
        navigate("/" + folder.path);
        triggerRerender(folder)
    }


    function deleteFolder(folder) {
        console.log(folder)
        const deleteFn = httpsCallable(functions, 'recursiveDelete');
        deleteFn({ path: folder.path })
            .then(function (result) {
                triggerRerender(folder)
                console.log('Delete success: ' + JSON.stringify(result));
            })
            .catch(function (err) {
                //logMessage('Delete failed, see console,');
                console.log(err);
            });
    }

    const onDragOver = (event) => {
        event.preventDefault();

    };

    const onDrop = (event) => {
        event.preventDefault();
        const data = JSON.parse(event.dataTransfer.getData('text/plain'));
        console.log(data)
        // update component state with new data
    };


    const handleInviteShare = () => {

        setShowInviteModal(!showInviteModal)
    }
    return (
        <div
            className="folder-container"
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {showInviteModal ?
                <div style={{ position: 'absolute', zIndex: 10 }}>
                    <InviteModal permissions={access} onClose={() => { setShowInviteModal(false) }} />
                </div> : null
            }
            <div className="primary-btn" style={{ display: 'flex', position: 'absolute', left: '8px', top: '8px' }} onClick={() => { openFolder(folder) }}>
                <img src={openIcon} style={{ height: '16px', marginRight: '6px', marginTop: '8px' }} alt="openIcon"></img>
                <div id="open-spec" style={{ display: 'grid', placeContent: 'center' }}>Open Folder</div>
            </div>

            {/* <div className="avatars-container">
                <div className="add-person-container">
                    <img id="addPerson" style={{ height: '24px' }} src={addPerson} alt="share" onClick={() => { handleInviteShare() }} />
                </div>
                {access.length > 0 ? access.map((person, index) => (
                    <Avatar width='34px' height='34px' profilePicURL={person.profile_pic} firstName={person.first_name} lastName={person.last_name}></Avatar>
                )) : null}
            </div> */}

            {folder.type==="Shared" ? 
                <div className="folder-type-pill">Shared</div>:<div  className="folder-type-pill">Private</div>
            }
            {/* <div className="folder-type-pill" onClick={()=>{setShowFolderDD(!folderDD)}}>
                
                <img style={{ height: '14px', marginRight: '6px' }} src={lockIcon} alt="lockIcon"></img>
                <div>{folder.type + " Folder"}</div>
                <div style={{ width: '1px', height: '16px', opacity: .5, marginLeft: '8px', backgroundColor: 'var(--primary-color)' }}></div>
                <img style={{ width: '16px', marginLeft: '4px' }} src={downArrow} alt="ddIcon"></img>
                {folderDD ? 
                <div id="folder-dd-container">
                    <h1>Add Members</h1>
                </div> :null   
            }
            </div> */}

            <div className="collection-trash-wrapper" onClick={() => { deleteFolder(folder) }}>
                <img src={trashIcon} style={{ height: '20px' }} alt="trashIcon"></img>
            </div>
            <div>
                <img className="folder-icon" src={FolderIcon}></img>
                <input className="folder-name-input" type="text" value={folderName} onChange={handleFolderNameChange} onBlur={() => { updateFolderName(folder.path) }} />
            </div>

            {/* <button>Invite Team</button> */}
        </div>
    );
};

export default Folder;
