import React, { useState } from 'react';
import { GettingStarted } from './Getting_Started/GettingStarted';
import SupportTable from './Suppored_Features/SupportedFeatures';
// Import other components as you create them
import BestPractices from './Best_Practices/BestPractices';
// import { Gotchas } from './Gotchas';
// import { BugReporting } from './BugReporting';

export const HelpDocumentation = () => {
    const [activeSection, setActiveSection] = useState('Getting Started');

    const renderSection = () => {
        switch (activeSection) {
            case 'Getting Started':
                return <GettingStarted />;
            case 'Support Table':
                return <SupportTable />;
            case 'Best Practices':
                return <BestPractices />;
            case 'Gotchas':
                // return <Gotchas />;
            case 'Bug Reporting':
                // return <BugReporting />;
            default:
                return <GettingStarted />;
        }
    };

    return (
        <div style={{ display: 'flex', backgroundColor: '#1e1e1e', color: '#ffffff', height: '100vh' }}>
            <div style={{ width: '250px', backgroundColor: '#2b2b2b', padding: '20px', borderRight: '2px solid #00c853', boxShadow: '4px 0 8px rgba(0, 0, 0, 0.2)' }}>
                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                    <li style={{
                        padding: '10px 20px',
                        marginBottom: '10px',
                        cursor: 'pointer',
                        fontSize: '18px',
                        borderRadius: '4px',
                        backgroundColor: activeSection === 'Getting Started' ? '#00c853' : '#2b2b2b',
                        color: activeSection === 'Getting Started' ? '#1e1e1e' : '#ffffff',
                    }} onClick={() => setActiveSection('Getting Started')}>
                        Getting Started
                    </li>
              
                    <li style={{
                        padding: '10px 20px',
                        marginBottom: '10px',
                        cursor: 'pointer',
                        fontSize: '18px',
                        borderRadius: '4px',
                        backgroundColor: activeSection === 'Best Practices' ? '#00c853' : '#2b2b2b',
                        color: activeSection === 'Best Practices' ? '#1e1e1e' : '#ffffff',
                    }} onClick={() => setActiveSection('Best Practices')}>
                        Best Practices
                    </li>
                    <li style={{
                        padding: '10px 20px',
                        marginBottom: '10px',
                        cursor: 'pointer',
                        fontSize: '18px',
                        borderRadius: '4px',
                        backgroundColor: activeSection === 'Support Table' ? '#00c853' : '#2b2b2b',
                        color: activeSection === 'Support Table' ? '#1e1e1e' : '#ffffff',
                    }} onClick={() => setActiveSection('Support Table')}>
                        Supported Parameters
                    </li>
                    <li style={{
                        padding: '10px 20px',
                        marginBottom: '10px',
                        cursor: 'pointer',
                        fontSize: '18px',
                        borderRadius: '4px',
                        backgroundColor: activeSection === 'Bug Reporting' ? '#00c853' : '#2b2b2b',
                        color: activeSection === 'Bug Reporting' ? '#1e1e1e' : '#ffffff',
                    }} onClick={() => setActiveSection('Bug Reporting')}>
                        Bug Reporting
                    </li>
                </ul>
            </div>
            <div style={{ flexGrow: 1, padding: '40px', overflowY: 'auto', backgroundColor: '#1e1e1e' }}>
                {renderSection()}
            </div>
        </div>
    );
};
