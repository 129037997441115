import React, { useState } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import './InviteToFolder.css';

const AddPeopleModal = ({ isOpen, onClose }) => {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState('');

  const handleChange = (newEmails) => {
    const validEmails = newEmails.filter(e => e.endsWith('@microsoft.com'));
    if (validEmails.length === newEmails.length) {
      setEmails(newEmails);
      setError('');
    } else {
      setError('All emails must end with @microsoft.com');
    }
  };

  const handleBlur = (e) => {
    const newEmails = e.target.value.split(',').map(email => email.trim()).filter(email => email.length > 0 && email.endsWith('@microsoft.com'));
    if (newEmails.length > 0) {
      handleChange([...emails, ...newEmails]);
      e.target.value = ''; // Clear the input field after adding emails
    }
  };

  const handleDone = () => {
    // Do something with the list of emails
    console.log(emails);
    onClose();
  };

  return (
    <div id="invite-modal-container">
      <h3>Share Folder</h3>
      <form>
        <label>
          Email Address:
          <TagsInput
            value={emails}
            onChange={handleChange}
            inputProps={{
              placeholder: 'Enter emails separated by commas',
              onBlur: handleBlur,

            }}
            addKeys={[9, 13, 32]} // Add emails when pressing tab, enter, or space
            onlyUnique={true}
          />
        </label>
        {error && <div className="error">{error}</div>}
      </form>
      <div className="button-group">
        <button className="secondary-btn" type="button" onClick={onClose}>
          Cancel
        </button>
        <button className="primary-btn" type="button" onClick={handleDone}>
          Done
        </button>
      </div>
    </div>
  );
};

export default AddPeopleModal;
