import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-web';
import './lottiepreviewcollection.css';
import { db } from '../../Auth/firebaseApp/firebaseApp';
import { doc, deleteDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';
import trashIcon from '../../Images/Icons/trash.svg';
import openIcon from '../../Images/Icons/open.svg';

const LottiePreview = (props) => {
    const [animation, setAnimation] = useState(null);
    useEffect(() => {
        let container=document.getElementById(`'lottie-container-${props.index}'`)
        setAnimation(Lottie.loadAnimation({
            container: container,
            animationData: JSON.parse(props.spec.lottieData),
            autoplay: false,
        }));
        

    }, [props.spec]);

    useEffect(()=>{
        if(animation){
            let container=document.getElementById(`'lottie-container-${props.index}'`)
            container.style.backgroundColor=props.spec.lottiePreviewBG
        }
        
    },[animation])

    const handleMouseEnter = () => {
        if (animation) {
            animation.play();
        }
    };

    const handleMouseLeave = () => {
        if (animation) {
            animation.pause();
        }
    };



    const deleteSpec = async (spec) => {
        await deleteDoc(doc(db, "specs", spec.spec_id));
        props.triggerRerender()
    }


    const onDragStart = (event) => {

        event.dataTransfer.setData('text/plain', JSON.stringify(props.spec));
    };



    return (
        <React.Fragment>
                <div
                    className="lottie-preview-collection"
                    draggable={true}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onDragStart={onDragStart}
                    
                >
                    <div id={`'lottie-container-${props.index}'`}></div>

                    <div className="collection-trash-wrapper" onClick={() => { deleteSpec(props.spec) }}>
                        <img src={trashIcon} style={{ height: '20px' }} alt="trashIcon"></img>
                    </div>
                    <div className="collection-bottom-wrapper">
                        <div className="lottie-preview-collection-name" style={{ color: 'white' }}>{props.spec.name}
                            <div className="lottie-preview-collection-details" style={{ color: 'white' }}>{JSON.parse(props.spec.lottieData).fr + "fps - " + JSON.parse(props.spec.lottieData).w + " x " + JSON.parse(props.spec.lottieData).h}</div>
                        </div>
                    </div>
                    <Link to={`/spec/${props.spec.spec_id}`} >
                        <div className="primary-btn" style={{ display: 'flex', position: 'absolute', left: '8px', top: '8px' }} >
                            <img src={openIcon} style={{ height: '16px', marginRight: '6px', marginTop: '8px' }} alt="openIcon"></img>
                            <div id="open-spec" style={{ display: 'grid', placeContent: 'center' }}>Open Spec</div>
                        </div>
                    </Link>
                </div> 
        </React.Fragment>

    );
};

export default LottiePreview;
