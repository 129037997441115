import React from 'react';
import './SupportedFeatures.css';

const SupportTable = () => {
    const tableData = [
        {
            category: 'Transform Animations',
            data: [
                { name: 'Position', web: '👍', mobile: '⛔️' },
                { name: 'Position (separated X/Y)', web: '⛔️', mobile: '⛔️' },
                { name: 'Scale (Uniform)', web: '👍', mobile: '⛔️' },
                { name: 'Scale (Non-Uniform)', web: '⛔️', mobile: '⛔️' },
                { name: 'Rotation', web: '👍', mobile: '⛔️' },
                { name: 'Opacity', web: '👍', mobile: '⛔️' },
                { name: 'Width (size)', web: '👍', mobile: '⛔️' },
                { name: 'Height (size)', web: '👍', mobile: '⛔️' },
                { name: 'Offset Anchor Point', web: '👍', mobile: '⛔️' },
                { name: 'Animated Anchor Point', web: '⛔️', mobile: '⛔️' },
                { name: 'Skew (Transform)', web: '⛔️', mobile: '⛔️' },
                { name: 'Skew (Axis)', web: '⛔️', mobile: '⛔️' },
                { name: 'Curved Motion Paths', web: '⛔️', mobile: '⛔️' },
                { name: 'Parenting', web: '⛔️', mobile: '⛔️' },
                { name: 'Auto Orient', web: '⛔️', mobile: '⛔️' },
                { name: 'Skew', web: '⛔️', mobile: '⛔️' },
            ]
        },
        {
            category: 'Shapes',
            data: [
                { name: 'Path Shapes', web: '👍', mobile: '⛔️' },
                { name: 'Merge Paths', web: '👍', mobile: '⛔️' },
                { name: 'Ellipse', web: '👍', mobile: '⛔️' },
                { name: 'Rectangle', web: '👍', mobile: '⛔️' },
                { name: 'Rounded Corner', web: '👍', mobile: '⛔️' },
                { name: 'Polystar', web: '👍', mobile: '⛔️' },
                { name: 'Solids', web: '👍', mobile: '⛔️' },
                { name: 'Group', web: '👍', mobile: '⛔️' },
                { name: 'Null', web: '⛔️', mobile: '⛔️' },
                { name: 'Repeater', web: '⛔️', mobile: '⛔️' },
                { name: 'Trim Path (individually)', web: '⛔️', mobile: '⛔️' },
                { name: 'Trim Path (simultaneously)', web: '⛔️', mobile: '⛔️' },
            ]
        },
        {
            category: 'Fills',
            data: [
                { name: 'Color (Shape Fills)', web: '👍', mobile: '⛔️' },
                { name: 'Opacity (Shape Opacity)', web: '⛔️', mobile: '⛔️' },
                { name: 'Fill Effect (Layers)', web: '👍', mobile: '⛔️' },
                { name: 'Radial Gradient', web: '⛔️', mobile: '⛔️' },
                { name: 'Linear Gradient', web: '⛔️', mobile: '⛔️' },
            ]
        },
        {
            category: 'Strokes',
            data: [
                { name: 'Color', web: '⛔️', mobile: '⛔️' },
                { name: 'Opacity', web: '⛔️', mobile: '⛔️' },
                { name: 'Width', web: '⛔️', mobile: '⛔️' },
                { name: 'Line Cap', web: '👍', mobile: '⛔️' },
                { name: 'Line Join', web: '👍', mobile: '⛔️' },
                { name: 'Miter Limit', web: '👍', mobile: '⛔️' },
                { name: 'Dashes', web: '👍', mobile: '⛔️' },
                { name: 'Gradient', web: '👍', mobile: '⛔️' },
            ]
        },
        {
            category: 'Interpolation',
            data: [

                { name: 'Linear Interpolation', web: '👍', mobile: '⛔️' },
                { name: 'Bezier Interpolation', web: '👍', mobile: '⛔️' },
                { name: 'Automatic Fluent Tokenization', web: '👍', mobile: '⛔️' },
                { name: 'Hold Interpolation', web: '⛔️', mobile: '⛔️' },
                { name: 'Spatial Bezier Interpolation', web: '⛔️', mobile: '⛔️' },
                { name: 'Rove Across Time', web: '⛔️', mobile: '⛔️' },
            ]
        },
        {
            category: 'Masks',
            data: [
                { name: 'Mask Path', web: '👍', mobile: '⛔️' },
                { name: 'Mask Opacity', web: '👍', mobile: '⛔️' },
                { name: 'Add', web: '👍', mobile: '⛔️' },
                { name: 'Subtract', web: '👍', mobile: '⛔️' },
                { name: 'Intersect', web: '⛔️', mobile: '⛔️' },
                { name: 'Lighten', web: '⛔️', mobile: '⛔️' },
                { name: 'Darken', web: '⛔️', mobile: '⛔️' },
                { name: 'Difference', web: '⛔️', mobile: '⛔️' },
                { name: 'Expansion', web: '👍', mobile: '⛔️' },
                { name: 'Feather', web: '⛔️', mobile: '⛔️' },
            ]
        },
        {
            category: 'Mattes',
            data: [
                { name: 'Alpha Matte', web: '⛔️', mobile: '⛔️' },
                { name: 'Alpha Inverted Matte', web: '⛔️', mobile: '⛔️' },
                { name: 'Luma Matte', web: '⛔️', mobile: '⛔️' },
                { name: 'Luma Inverted Matte', web: '⛔️', mobile: '⛔️' },
            ]
        },
        {
            category: 'Merge Paths',
            data: [
                { name: 'Merge', web: '👍', mobile: '⛔️' },
                { name: 'Add', web: '👍', mobile: '⛔️' },
                { name: 'Subtract', web: '👍', mobile: '⛔️' },
                { name: 'Intersect', web: '👍', mobile: '⛔️' },
                { name: 'Exclude Intersection', web: '👍', mobile: '⛔️' },
            ]
        },
        {
            category: 'Layer Effects',
            data: [
                { name: 'Fill', web: '👍', mobile: '⛔️' },
                { name: 'Stroke', web: '👍', mobile: '⛔️' },
            ]
        },
        ]

        return (
          <div>
           <h1 style={{marginLeft:'10%'}}className="motionspec-header">What's Supported from After Effects?</h1>
            <div className="table-container">
              <table className="supportedFeatures" border="1" width="100%">
                <thead>
                  <tr>
                    <th className="strong">Category</th>
                    <th className="strong">Fluent Web</th>
                    <th className="strong">Fluent Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((category, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th className="strong" colSpan="3">{category.category}</th>
                      </tr>
                      {category.data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td className={item.web === '👍' ? 'thumbs-up' : 'thumbs-down'}>{item.web}</td>
                          <td className={item.mobile === '👍' ? 'thumbs-up' : 'thumbs-down'}>{item.mobile}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            </div>
          );
        };
        
        export default SupportTable;