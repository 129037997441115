import React from 'react';
import './GettingStarted.css';

export const GettingStarted = () => {
  return (
    <div className="motionspec-container">
      <h1 className="motionspec-header">Getting Started with MotionSpec.io</h1>

      <div className="motionspec-section">
        <p className="motionspec-paragraph">
          Welcome to MotionSpec.io! If you're looking to create powerful, high-performance animations for your web projects, you're in the right place. MotionSpec.io simplifies the process by taking JSON files exported from Bodymovin—the same format used by Lottie—and converting them into fully self-contained React components. This means you can skip the Lottie web player altogether, resulting in faster, smoother animations that don't compromise on creativity.
        </p>
        <p className="motionspec-paragraph">
          Whether you're crafting detailed icon animations, complex FRE animations, or lively illustrations, MotionSpec.io empowers you to push the boundaries of what's possible without the usual headaches of long development cycles or performance bottlenecks. Plus, you can easily spec out motion for engineers and even run live-coded prototypes directly on StackBlitz.
        </p>
      </div>

      <div className="motionspec-section">
        <h2 className="motionspec-stepTitle">1. Installing the Bodymovin Plugin</h2>
        <p className="motionspec-paragraph">
          To get started, you'll need the Bodymovin plugin for Adobe After Effects. This plugin allows you to export your animations in a JSON format that MotionSpec.io can interpret.
          <br />
          You can download the Bodymovin plugin from <a href="https://aescripts.com/bodymovin/" target="_blank" rel="noopener noreferrer">aescripts.com</a>. After downloading, use the ZXP Installer to add it to After Effects. If you don't have the ZXP Installer, you can get it from <a href="https://aescripts.com/learn/zxp-installer/" target="_blank" rel="noopener noreferrer">aescripts.com</a> as well.
        </p>
        <div className="motionspec-imagePlaceholder">Image Placeholder</div>
      </div>

      <div className="motionspec-section">
        <h2 className="motionspec-stepTitle">2. Configuring Bodymovin for Optimal Exports</h2>
        <p className="motionspec-paragraph">
          With Bodymovin installed, it's time to configure your export settings to get the best results with MotionSpec.io. First, make sure the <strong>Glyphs</strong> option is enabled in the settings. This converts any fonts in your animation into vector shapes, ensuring they render perfectly across all devices.
        </p>
        <p className="motionspec-paragraph">
          If your animation includes image assets, go to the 'Assets' tab in the Bodymovin panel and make sure 'Include in JSON' is selected. This will bundle your images directly into the JSON file, keeping everything neatly contained. We also recommend setting image compression to 75 for a good balance between quality and file size.
        </p>
        <div className="motionspec-imagePlaceholder">Image Placeholder</div>
      </div>

      <div className="motionspec-section">
        <h2 className="motionspec-stepTitle">3. Best Practices for Smooth Animations</h2>
        <p className="motionspec-paragraph">
          Before you start exporting your animations, take a moment to review our <a href="/best-practices" target="_blank" rel="noopener noreferrer">Best Practices</a> page. Here, you'll find tips on how to set up your layers, troubleshoot common issues, and optimize your animations for the best performance in MotionSpec.io. This is essential reading to ensure your projects run smoothly.
        </p>
      </div>

      <div className="motionspec-section">
        <h2 className="motionspec-stepTitle">4. Next Steps: Bring Your Animations to Life</h2>
        <p className="motionspec-paragraph">
          You're now ready to start creating with MotionSpec.io! Once your animations are configured and exported, you can begin experimenting with motion specs, whether you're working on a small icon or a full-scale illustration. Dive into our documentation to learn more about how to integrate these animations into your React projects, and explore the possibilities of live prototyping on StackBlitz. The only limit is your imagination!
        </p>
        <div className="motionspec-imagePlaceholder">Image Placeholder</div>
      </div>

      <div className="motionspec-section">
        <h2 className="motionspec-stepTitle">5. Frequently Asked Questions (FAQ)</h2>
        <p className="motionspec-paragraph">
          <strong>Q: Can I use .lottiefiles with MotionSpec.io?</strong>
          <br />
          A: No, MotionSpec.io does not support .lottiefiles. It only works with JSON files exported from Bodymovin.
        </p>
        <p className="motionspec-paragraph">
          <strong>Q: What types of animations can I create with MotionSpec.io?</strong>
          <br />
          A: MotionSpec.io is versatile and can handle a wide range of animations, from simple icon animations to complex full-page transitions and illustrations.
        </p>
        <p className="motionspec-paragraph">
          <strong>Q: How do I troubleshoot issues with my animation not rendering correctly?</strong>
          <br />
          A: Check the <a href="/best-practices" target="_blank" rel="noopener noreferrer">Best Practices</a> page for tips on troubleshooting common issues, such as incorrect layer setup or unsupported features in Bodymovin exports.
        </p>
      </div>

      <div className="motionspec-section">
        <h2 className="motionspec-stepTitle">6. Troubleshooting Tips</h2>
        <p className="motionspec-paragraph">
          If you encounter issues with your animations not displaying correctly, here are a few steps you can take:
        </p>
        <ul className="motionspec-list">
          <li>Ensure that the Glyphs option is enabled in Bodymovin to avoid font rendering issues.</li>
          <li>Check that all assets are included in the JSON file, especially if your animation uses images.</li>
          <li>Review the layers in After Effects to ensure they are supported by Bodymovin (e.g., avoid using effects that Bodymovin doesn't support).</li>
          <li>Use the built-in preview in After Effects to ensure your animation exports correctly before importing it into MotionSpec.io.</li>
        </ul>
      </div>
    </div>
  );
};
